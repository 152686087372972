import "../styles/NavigationBar.css";
import axios from "axios";
import React, { useState, useEffect } from "react";

function HeaderTitle() {
  return (
      <header className="header-title">BUDAPEST GAY GUIDE</header>
  );
}

export default HeaderTitle;
